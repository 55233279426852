@charset "utf-8";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --red-600: #e53e3e;
  --font-muli: "Muli", sans-serif;
  --font-tajawal: "Tajawal", sans-serif;
  --enterprise-black: #000000;
  --swiper-theme-color: #761E33;
  --swiper-pagination-color: #761E33;
}

body {
  font-family: "Muli", sans-serif;
  @apply text-gray-900;
}

:lang(ar) {
  direction: rtl;
  font-family: var(--font-tajawal);
}

:lang(en) {
  direction: ltr;
  font-family: var(--font-muli);
}

.page-container {
  padding-top: 75px;
}

.sticky {
  z-index: 3;

  .adjuster-top-position {
    top: 80px;
  }
}

.max-w-one-fourth {
  max-width: 25%;
}
.max-w-half {
  max-width: 50%;
}
.max-w-three-fourth {
  max-width: 75%;
}
.max-w-32px {
  max-width: 32px;
}
.height-half {
  height: 50%;
}
.text-gray-850 {
  color: var(--gray-850);
}
.text-gray-750 {
  color: var(--gray-750);
}
.text-gray-650 {
  color: var(--gray-650);
}
.text-gray-450 {
  color: var(--gray-450);
}
.border-gray-450 {
  border-color: var(--gray-450);
}
.rounded-xl {
  border-radius: 1rem;
}
.bg-transparent-white {
  background-color: rgba(255, 255, 255, 0.5);
}
.bg-transparent-black {
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-turquoise-darkblue-gradient {
  background: #081542;
  background: linear-gradient(
    0deg,
    rgba(8, 21, 66, 1) 50%,
    rgba(42, 201, 196, 1) 100%
  );
}
.line-height-half {
  line-height: 0.5rem;
}
.min-w-2 {
  min-width: 2rem;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-5 {
  z-index: 5;
}
.opacity-10 {
  opacity: 0.1;
}
.m-t-negative-5 {
  margin-top: -5px;
}
.top-90 {
  top: 90px;
}
.top-70 {
  top: 70px;
}

.sticky-inner-wrapper {
  z-index: 3;
}
.multi-paragraph-text-container > p {
  display: block;
  margin-bottom: 1rem;
}
.multi-paragraph-text-container > p:last-child {
  margin-bottom: 0;
}
.icon-btn {
  background-size: contain;
  display: block;
  text-indent: -9999em;
}
.icon-btn.__delete {
  background: url("/icons/icon-delete.svg") center center no-repeat;
  height: 19px;
  width: 16px;
}
.page-container__home {
  .main-nav .language-switch {
    @apply mr-2;
    display: inline-block;
    font-family: "Tajawal", sans-serif;
  }
}
.main-nav {
  background: #fff;
  z-index: 2;
  .brand {
    background-size: 85px;
    text-indent: -999em;
    width: 85px;
  }
  .brand:lang(ar) {
    background-size: 85px;
  }
  .icon-back {
    background: url("/icons/icon-back.svg") center center no-repeat;
    background-size: 12px;
    text-indent: -999em;
    width: 12px;
  }
  .language-switch {
    display: none;
  }
  .cart-count .icon-cart {
    background: url("/icons/icon-cart.svg") center center no-repeat;
    background-size: 25px;
    height: 30px;
    text-indent: -999em;
    width: 30px;
    position: absolute;
    right: 20px;
    top: 25px;
  }
  .cart-count .cart-item-count {
    position: absolute;
    background-color: #000;
    border-radius: 50px;
    color: #fff;
    font-size: 0.75rem;
    right: 30px;
    bottom: 25px;
    padding: 0 5px;
    min-width: 20px;
    min-height: 20px;
    text-align: center;
    line-height: 20px;
    font-weight: 400;
  }
}

.Toastify__toast {
  @apply text-sm;
  font-family: var(--font-montserrat);
  .item__errors .name,
  .list__errors .name {
    font-weight: 600;
  }
  .item__errors .name::after,
  .item__errors .name::after {
    content: ": ";
  }
  .item__errors .list-item-pos,
  .list__errors .list-item-pos {
    @apply hidden;
  }
}

.lottie-failure,
.lottie-success {
  margin-right: -100px;
  right: 50%;
  top: -150px;
}

.swiper-pagination-bullet-active {
  @apply bg-gray-800;
}

#country-switch:lang(ar) {
  direction: ltr;
  font-family: "Muli", sans-serif;
}
.country-switch-btn span:lang(ar),
#country-switch ul:lang(ar),
#country-switch span:lang(ar) {
  font-family: "Muli", sans-serif;
}

input::placeholder {
  color: var(--enterprise-black);
}
.list-items {
  @apply absolute inset-x-0 shadow z-2 overflow-x-auto bg-white;
  top: 46px;
  max-height: 18rem;
}
.list-item {
  @apply p-2;
}
.list-item.list-item__active {
  @apply bg-gray-100;
}
.input-container-wrap__search {
  @apply flex;
  .input-container__search {
    @apply bg-white relative;
  }
  .icon-remove {
    @apply absolute block cursor-pointer h-6 w-6;
    background: url("/icons/icon_close.svg") no-repeat center center;
    background-size: 24px;
    top: 10px;
    right: 5px;
    text-indent: -999em;
  }
  .product-search-input {
    background: url("/icons/icon-search.svg") 7px 15px no-repeat;
    background-size: 15px;
    padding: 0.85rem 0.5rem 0.85rem 2rem;
  }
}

/* purgecss start ignore */
.search-container {
  @apply m-auto w-full bg-white;
}
.search-container.search-container__floating {
  @apply block;
  width: 285px;
}
.search-container.search-container__static .input-container-wrap__search {
  @apply m-auto;
  width: 285px;
}
/* purgecss end ignore */

html[dir="rtl"] {
  .main-nav .cart-count .icon-cart {
    right: auto;
    left: 20px;
  }
  .main-nav .cart-count .cart-item-count {
    right: auto;
    left: 30px;
  }
  .main-nav .icon-back {
    transform: rotate(180deg);
  }
  .main-nav .language-switch {
    @apply mr-0 ml-2;
    font-family: "Muli", sans-serif;
  }
  .input-container-wrap__search .icon-remove {
    background: url("/icons/icon_close.svg") no-repeat center center;
    background-size: 24px;
    left: 5px;
    right: auto;
  }
  .product-search-input {
    background: url("/icons/icon-search.svg") 98% 12px no-repeat;
    padding: 0.85rem 2rem 0.85rem 0.5rem;
  }
}
